
:root {
    --main:#635e87;
    --hover: #8B87AB;
    --background: #2c2c2c;
}

.container {
    display: flex;
    flex-direction: column;
    margin: auto auto;
    text-align: center;
    max-width: 800px;
    flex-grow: 1;
}

.payoutHeading {
    padding: 1rem 0 .5rem 0;

}

.payoutDetails,
.PercentageDetails,
.payoutPercentageText {
    font-size: small;
    font-style: italic;
    color: rgba(255, 255, 255, 0.442);
    text-wrap: wrap;
    padding-bottom: .5rem;
}

.payoutsInputContainer {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 300px;
    min-width: 100px;
    padding: 1rem 0 1rem 0;
}

.payoutsInputContainer input, select {
    margin: .3rem 0 1rem 0;
    padding: .3rem;
    border-radius: 20px;
    background-color: white;
    text-align: center;
    font-size: medium;
    color: #000;
    
}

.payoutsInputContainer input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.payoutsInputContainer input:focus {
    outline-color: var(--main);
}

.payoutsBreakline {
    margin-bottom: 1rem;
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(225, 225, 225, .5), rgba(99, 94, 135, .9), rgba(225, 225, 225, .5));
}

.payoutsBtn {
    width: 100%;
    height: 3rem;
    font-weight: 600;
    color: white;
    border-radius: 15px;
    background-color: var(--main);
    transition: .3s;
    margin-bottom: .5rem;
}

.payoutsBtn:hover {
    cursor: pointer;
    background-color: var(--hover);
}

.totals {
    font-weight: 600;
}

.totalsNumber {
    color: var(--hover);
    margin-right: 1rem;
    font-style: italic;
    font-weight: bold;
}

.placePayout {
    margin-left: .5rem;
    font-weight: 500;
    font-style: italic;
}

.Payouts {
    margin: .5rem auto 2rem auto;

}

.payoutSingle {
    margin-bottom: .25rem;
}

.moneyIcon {
    margin-top: .5rem;
    font-size: 2rem;
}

.payoutsTotalText {
    font-weight: 600;
    font-style: italic;
    margin-top: 1rem;
    color: var(--hover);
    font-size: large;
   align-items: center;
}

.totalIconsTick {
    margin-left: 1rem;
    fill: greenyellow;
    position: relative;
    top:.1rem
}

.totalIconsCross {
    margin-left: 1rem;
    fill: red;
    position: relative;
    top:.1rem;
    
}

.playerErrorText {
    color: red;
    font-weight: bold;
    font-size: large;
    
}

#percentage {
    margin-bottom: .2rem;
}

.diffText {
    margin: .25rem 0 2rem 0;
    font-weight: 500;
    font-style: italic;
}

.slider-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    height: 40px;
}

/* .sliderThree */
.slider {
    width: 80px;
    height: 26px;
    background: #333;
    margin: 20px auto;
    position: relative;
    border-radius: 50px;
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.2);
  }
  .slider:after,
  #remove-slider:after {
    content: 'OFF';
    color: #000;
    position: absolute;
    right: 10px;
    z-index: 0;
    font: 12px/26px Arial, sans-serif;
    font-weight: bold;
    text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.15);
  }
  .slider:before,
  #remove-slider:before {
    content: 'ON';
    color: #27ae60;
    position: absolute;
    left: 10px;
    z-index: 0;
    font: 12px/26px Arial, sans-serif;
    font-weight: bold;
  }
  .slider label {
    display: block;
    width: 34px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    top: 3px;
    left: 3px;
    z-index: 1;
    background: #fcfff4;
    background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
    border-radius: 50px;
    transition: all 0.4s ease;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  }
  .slider input[type=checkbox] {
    visibility: hidden;
  }
  .slider input[type=checkbox]:checked + label {
    left: 43px;
  }
  /* end .sliderThree */