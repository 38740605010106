.ThankYouContainer {
    display: flex;
    flex-direction: column;
    margin: auto auto;
    text-align: center;
}

.ThankYouIcon {
    margin: 0 auto;
    margin-bottom: 2rem;
    font-size:5rem;
    fill: rgb(39, 190, 39);
}