.icmContainer {
    display: flex;
    flex-direction: column;
    margin: 1rem auto 0 auto;
    max-width: 1000px;
    align-items: center;
}

.icmHeading {
    margin-bottom: 1rem;
    text-align: center;

}

.icmDetails {
    font-size: small;
    font-style: italic;
    color: rgba(255, 255, 255, 0.442);
    text-align: center;
    text-wrap: wrap;
    max-width: 600px;
    margin: 1rem 3rem;

}

.getPlayers {
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 50%;
    margin: 0 auto;
    align-items: center;
}

#numofPlayers {
    margin-top: .5rem;
    min-width: 12rem;

}

.getPlayers input, 
.icmInput input {
    margin-bottom: .5rem;
    padding: .3rem;
    border-radius: 20px;
    background-color: white;
    text-align: center;
    font-size: medium;
    color: #000;
    min-width: 15vw;
    max-width: 30vw;
    
}
.getPlayers input::-webkit-inner-spin-button, 
.icmInput input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.getPlayers input:focus, 
.icmInput input:focus {
    outline-color: var(--main);
}

.getPlayerBtn {
    width: 100%;
    height: 3rem;
    font-weight: 600;
    color: white;
    border-radius: 15px;
    background-color: var(--main);
    transition: .3s;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.getPlayerBtn:hover {
    cursor: pointer;
    background-color: var(--hover);
}

.icmInput {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icmPlayerIndex {
    margin-right: 1rem;
}

.icmInputSingle {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.icmCalcBtn {
    width: 50%;
    height: 3rem;
    font-weight: 600;
    color: white;
    border-radius: 15px;
    background-color: var(--main);
    transition: .3s;
    display: flex;
    margin: 0 auto .5rem auto;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
 
}

.icmCalcBtn:hover {
    cursor: pointer;
    background-color: var(--hover);
}

.icmPayouts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0 auto 2rem auto;
}

.icmTotalsSingle {
    margin-left: 1rem;
}

.icmTotals {
    margin: 1rem 0 1rem 0;
}


