@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


* {
    margin: 0;
    padding: 0;
    font-family: montserrat, reboto, Arial, Helvetica, sans-serif;
    background-color: #2c2c2c;
    color: white;
}

.app {
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    justify-content: space-between;
}